
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import Person from "../../models/societymgmt/Person";
import PersonService from "../../services/societymgmt/PersonService";

@Component
export default class PersonComponent extends Vue {
  public person: Person = new Person();
  public dateOfBirth = "";
  public dateOfBirthValue = "";
  public genderOptions = [
    { value: "M", text: i18n.tc("societymgmt.male") },
    { value: "F", text: i18n.tc("societymgmt.female") },
  ];

  async mounted(): Promise<void> {
    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      const p = await PersonService.get(this.$route.params.id);
      console.log("person");
      this.person = p;
    }
  }

  async save(): Promise<void> {
    PersonService.save(this.person).then((item) => {
      item = new Person(item); // otherwise you cannot use the functions
      if (item) {
        this.person = item;
        ToastService.Success(
          i18n.tc("labels.saved"),
          this.person.getFullname()
        );
        this.handleBack();
      }
    });
  }

  handleBack(): void {
    this.$router.back();
  }
}
